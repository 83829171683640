import React from 'react'
import Seo from '@components/Seo'
import {LANG} from '@constants/theme'
import {IndexTemplate} from '@pages/index'
import '@fontsource/noto-sans-tc'
import '../css/index.css'

export default function IndexPage() {
  return (
    <>
      <Seo
        title={'想問 ㅡ 一千萬段戀情，唯一的應用'}
        description={'情侶一起共同經營的互動日記APP'}
        lang={LANG.tw}
      />
      <IndexTemplate lang={LANG.tw} />
    </>
  )
}
